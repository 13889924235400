import "./Pricing.less";
import { useIntl } from "react-intl";
import pricingClip1 from '../../assets/images/clip/pin-clip-6.webp';
import pricingTickClip from '../../assets/images/clip/pricing-tick-clip.webp';
import pricingCardClip from '../../assets/images/clip/pricing-card-clip.webp';
import burseTextLogoSlogan from '../../assets/images/logo/burse-text-logo-slogan.webp';
import burseTextLogoSloganCN from '../../assets/images/logo/burse-text-logo-slogan-cn.webp';
import pricingBurseLogo from '../../assets/images/logo/pricing-burse-logo.webp';
import { applicationFee, minReloadLimit, posFee, reloadFee, withdrawalFee, yearlyFee } from "../../content/HomeContent";
import { useLayout } from "../../hooks/LayoutContext";

interface Props {
    refPricing: any;
}

const Pricing = ({ refPricing }: Props) => {
    const { layoutState } = useLayout();
    const intl = useIntl();
    const feeContentList = [
        {
            content: "BURSE_FEE_1",
            fee: reloadFee
        },
        {
            content: "BURSE_FEE_2",
            fee: applicationFee
        },
        {
            content: "BURSE_FEE_3",
        },
        {
            content: "BURSE_FEE_5",
        },
        {
            content: "BURSE_FEE_4",
            fee: yearlyFee,
        },
    ]

    const limitContentList = [
        {
            content: "BURSE_LIMIT_1_A",
            fee: minReloadLimit
        },
        {
            content: "BURSE_LIMIT_2",
        },
        {
            content: "BURSE_LIMIT_3",
        },
    ]

    const generateFeeList = (item: any, index: any) => {
        return (
            <div key={index} className="pricing-text-box">
                <img className="pricing-text-img" alt="pricing-text" src={pricingTickClip} />
                <div className="pricing-text">
                    {
                        item.fee ?
                            <span className="highlight-text">{item.fee} </span>
                            :
                            null
                    }
                    {intl.formatMessage({ id: item.content })}
                    {
                        item.content === 'BURSE_FEE_4' ?
                            <>
                                <br />
                                {intl.formatMessage({ id: "BURSE_FEE_4_A" })}
                            </>
                            :
                            null
                    }
                </div>
            </div>
        )
    };

    const generateLimitList = (item: any, index: any) => {
        return (
            <div key={index} className="pricing-text-box">
                <img className="pricing-text-img" alt="pricing-text" src={pricingTickClip} />
                <div className="pricing-text">
                    {
                        item.content === 'BURSE_LIMIT_1_A' ?
                            <>
                                {intl.formatMessage({ id: 'BURSE_LIMIT_1_A' })}
                                <span className="highlight-text">{item.fee} </span>
                                {intl.formatMessage({ id: 'BURSE_LIMIT_1_B' })}
                            </>
                            :
                            <>
                                {intl.formatMessage({ id: item.content })}
                            </>
                    }
                </div>
            </div>
        )
    };
    return (
        <>
            <div className="pricing-wrap">
                <div className="pricing-content-wrap">
                    <div className="pricing-clip-img-wrap">
                        <img className="pricing-clip-img" alt="pricing-clip" src={pricingClip1} />
                    </div>
                    <div className="pricing-title-wrap" ref={refPricing}>
                        <span>{intl.formatMessage({ id: "CHARGES" })}</span>
                    </div>
                    <div className="pricing-box-wrap">
                        <div className="pricing-box fee-box">
                            <div className="pricing-content-title">
                                <div className="pricing-content-title-text">
                                    <span>{intl.formatMessage({ id: "FEE_CHARGES" })}</span>
                                </div>
                                <div className="pricing-content-title-img">
                                    <img className="pricing-title-img" alt="pricing-clip" src={pricingCardClip} />
                                </div>
                            </div>
                            <hr />
                            <div className="pricing-content-box">
                                {
                                    feeContentList.map((item: any, index: any) => {
                                        return generateFeeList(item, index)
                                    })
                                }
                            </div>
                        </div>
                        <div className="pricing-box limit-box">
                            <div className="pricing-content-title">
                                <div className="pricing-content-title-text">
                                    <span>{intl.formatMessage({ id: "LIMIT" })}</span>
                                </div>
                                <div className="pricing-content-title-img">
                                    <img className="pricing-title-img" alt="pricing-clip" src={pricingCardClip} />
                                </div>
                            </div>
                            <hr />
                            <div className="pricing-content-box">
                                {
                                    limitContentList.map((item: any, index: any) => {
                                        return generateLimitList(item, index)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="pricing-bottom-content-wrap">
                        <div className="pricing-bottom-text-logo-wrap">
                            <img className="pricing-bottom-text" alt="pricing-bottom-text" src={(layoutState.locale === 'en' ? burseTextLogoSlogan : burseTextLogoSloganCN)} />
                        </div>
                        <div className="pricing-bottom-logo-wrap">
                            <img className="pricing-bottom-logo" alt="pricing-bottom-logo" src={pricingBurseLogo} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing;