export const officeAddress = "No 13-1 GF, Jalan PJU 5/13,\nDataran Sunway, Kota Damansara, 47810\nPetaling Jaya, Selangor";
export const contactNumber = "+6011 61175221";
export const enquiryEmail = "customercare@burse2u.com";
export const reloadFee = "3.9%";
export const applicationFee = "88 USD";
export const posFee = "2.5%";
export const yearlyFee = "20 USDT";
export const monthlyFeePromo = "*0 USDT";
export const minReloadLimit = "200 USDT";
export const withdrawalFee = '2%';
export const loginUrl = "https://secure.burse2u.com/auth";