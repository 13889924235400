import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import headerBurseLogo from '../../assets/images/logo/header-burse-logo.webp';
import aboutBurseLogo from '../../assets/images/logo/about-burse-logo.webp';
import aboutClip1 from '../../assets/images/clip/pin-clip-2.webp';
import aboutNo1 from '../../assets/images/clip/about-no-1.webp';
import aboutNo2 from '../../assets/images/clip/about-no-2.webp';
import aboutNo3 from '../../assets/images/clip/about-no-3.webp';
import aboutNo4 from '../../assets/images/clip/about-no-4.webp';
import aboutNo5 from '../../assets/images/clip/about-no-5.webp';
import "./About.less";
import { loginUrl } from "../../content/HomeContent";
import LanguageSwitcher from "../../widget/LanguageSwitcher";

interface Props {
    refHome: any;
    refAbout: any;
    refUSDTProbs: any;
    refSolution: any;
    refWorks: any;
    refPricing: any;
    refFooter: any;
    isSticky: boolean;
    isTransparent: boolean;
    currentActive: any;
}

const About = ({ refHome, refAbout, refUSDTProbs, refSolution, refWorks, refPricing, refFooter, isSticky, isTransparent, currentActive }: Props) => {
    const intl = useIntl();
    const [headerClass, setHeaderClass] = useState<string>("header-container");
    const [activeTab, setActiveTab] = useState('home');

    const generateAboutItem = (logo: any, content: any, boldText?: any) => {
        return (
            <div className="about-content-list">
                <div className="about-content-count">
                    <img className="about-number-img" alt="burse-about" src={logo} />
                </div>
                <div className="about-content-info">
                    {boldText ? <b>{intl.formatMessage({ id: boldText })}</b> : null}{intl.formatMessage({ id: content })}
                </div>
            </div>
        )
    };

    useEffect(() => {
        let className;
        if (isSticky === true) {
            className = headerClass + " sticky";
        } else {
            className = headerClass.replaceAll("sticky", "");
        }
        setHeaderClass(className);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSticky]);

    useEffect(() => {
        let className;
        if (isTransparent === true) {
            className = headerClass + " transparent-header";
        } else {
            className = headerClass.replaceAll("transparent-header", "");
        }
        setHeaderClass(className);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTransparent]);

    useEffect(() => {
        setActiveTab(currentActive)
    }, [currentActive]);

    const menuNav = (location: string) => {
        if (location !== 'memberLogin') {
            setActiveTab(location);
        }
        if (location === 'home') {
            if (refHome.current) {
                refHome.current.scrollIntoView();
            }
        }
        if (location === 'about') {
            if (refAbout.current) {
                refAbout.current.scrollIntoView();
            }
        }
        if (location === 'usdtProbs') {
            if (refUSDTProbs.current) {
                refUSDTProbs.current.scrollIntoView();
            }
        }
        if (location === 'solution') {
            if (refSolution.current) {
                refSolution.current.scrollIntoView();
            }
        }
        if (location === 'works') {
            if (refWorks.current) {
                refWorks.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (location === 'pricing') {
            if (refPricing.current) {
                refPricing.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (location === 'footer') {
            if (refFooter.current) {
                refFooter.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (location === 'memberLogin') {
            window.open(loginUrl)
        }
    }

    const menuItems = [
        {
            menuTitle: 'ABOUT',
            menuNav: 'about',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
        {
            menuTitle: 'USDT_TO_CASH',
            menuNav: 'usdtProbs',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
        {
            menuTitle: 'SOLUTIONS',
            menuNav: 'solution',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
        {
            menuTitle: 'HOW_IT_WORKS',
            menuNav: 'works',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
        {
            menuTitle: 'CHARGES',
            menuNav: 'pricing',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
        {
            menuTitle: 'CONTACT',
            menuNav: 'footer',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
        {
            menuTitle: 'MEMBER_LOGIN',
            menuNav: 'memberLogin',
            className: 'menu-wrap',
            activeClassname: 'menu-wrap menu-active'
        },
    ]

    return (
        <>
            <div className={headerClass}>
                <img className="header-logo" alt="burse-logo-small" onClick={() => menuNav('home')} src={headerBurseLogo} />
                {
                    menuItems.map((menu: any, index: any) => {
                        return (
                            <div key={index} onClick={() => menuNav(menu.menuNav)} className={activeTab === menu.menuNav ? menu.activeClassname : menu.className}>{intl.formatMessage({ id: menu.menuTitle })}</div>
                        )
                    })
                }
                <div className="header-spacing"></div>
                <LanguageSwitcher refHome={refHome}></LanguageSwitcher>
            </div>
            <div className="about-wrap" ref={refAbout}>
                <div className="about-text-wrap header-gap">
                    <div className="about-title">
                        {intl.formatMessage({ id: "BURSE_SLOGAN" })}
                    </div>
                    <div className="about-content">
                        {intl.formatMessage({ id: "BURSE_ABOUT_US" })}
                    </div>
                    <div className="about-logo-wrap">
                        <img className="about-logo-img" alt="burse-logo" src={aboutBurseLogo} />
                    </div>
                </div>
                <div className="about-content-wrap">
                    <div className="about-vertical-title-wrap">
                        <span className="about-vertical-title">
                            {intl.formatMessage({ id: "ABOUT" })}
                        </span>
                    </div>
                    <div className="about-content-box">
                        <div className="about-side-logo">
                            <div className="about-side-logo-position">
                                <img className="about-clip-img" alt="about-clip" src={aboutClip1} />
                            </div>
                            <div className="about-title-text">
                                {intl.formatMessage({ id: "BURSE_ABOUT" })}
                            </div>
                        </div>
                        <div className="about-side-content">
                            <div className="about-side-content-list">
                                {generateAboutItem(aboutNo1, "BURSE_ABOUT_1")}
                                {generateAboutItem(aboutNo2, "BURSE_ABOUT_2", "BURSE_ABOUT_2_TITLE")}
                                {generateAboutItem(aboutNo3, "BURSE_ABOUT_3", "BURSE_ABOUT_3_TITLE")}
                                {generateAboutItem(aboutNo4, "BURSE_ABOUT_4", "BURSE_ABOUT_4_TITLE")}
                                {generateAboutItem(aboutNo5, "BURSE_ABOUT_5", "BURSE_ABOUT_5_TITLE")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default About;