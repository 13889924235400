import "./Solution.less";
import solutionBurseLogoEN from '../../assets/images/logo/solution-burse-logo-en.webp';
import solutionBurseLogoCN from '../../assets/images/logo/solution-burse-logo-cn.webp';
import solutionClipi1 from '../../assets/images/clip/pin-clip-4.webp';
import solutionTextboxClip1 from '../../assets/images/clip/solution-textbox-clip.webp';
import { useIntl } from "react-intl";
import { useLayout } from "../../hooks/LayoutContext";

interface Props {
    refSolution: any;
}

const Solution = ({ refSolution }: Props) => {
    const intl = useIntl();
    const { layoutState } = useLayout();

    const generateSolutionItem = (title: any, content: any) => {
        return (
            <div className="solution-textbox">
                <div className="solution-title">
                    <img className="solution-title-clip" alt="solution-textbox-clip" src={solutionTextboxClip1} />
                    <div className="solution-title-text">{intl.formatMessage({ id: title })}</div>
                </div>
                <div className="solution-content">
                    {intl.formatMessage({ id: content })}
                </div>
            </div>
        )
    };

    return (
        <>
            <div className="solution-wrap">
                {
                    layoutState.dimension.width > 768 ?
                        null :
                        <div className="solution-upper-mobile-box"></div>
                }
                <div className="solution-upper-content-wrap">
                    <div className="solution-upper-img-wrap">
                        <img className="solution-clip-img" alt="solution-clip" src={solutionClipi1} />
                    </div>
                    <div className="solution-lower-img-wrap" ref={refSolution}>
                        <img className="solution-logo-img" alt="solution-logo" src={(layoutState.locale === 'en' ? solutionBurseLogoEN : solutionBurseLogoCN)} />
                    </div>
                </div>

                {
                    layoutState.dimension.width > 768 ?
                        <>
                            <div className="solution-lower-content-wrap">
                                {generateSolutionItem("BURSE_SOLUTION_1_TITLE", "BURSE_SOLUTION_1_CONTENT")}
                                {generateSolutionItem("BURSE_SOLUTION_2_TITLE", "BURSE_SOLUTION_2_CONTENT")}
                                {generateSolutionItem("BURSE_SOLUTION_3_TITLE", "BURSE_SOLUTION_3_CONTENT")}
                            </div>
                            <div className="solution-lower-content-wrap">
                                {generateSolutionItem("BURSE_SOLUTION_4_TITLE", "BURSE_SOLUTION_4_CONTENT")}
                                {generateSolutionItem("BURSE_SOLUTION_5_TITLE", "BURSE_SOLUTION_5_CONTENT")}
                            </div>
                        </>
                        :
                        <div className="solution-lower-content-wrap solution-mobile-wrap">
                            {generateSolutionItem("BURSE_SOLUTION_1_TITLE", "BURSE_SOLUTION_1_CONTENT")}
                            {generateSolutionItem("BURSE_SOLUTION_2_TITLE", "BURSE_SOLUTION_2_CONTENT")}
                            {generateSolutionItem("BURSE_SOLUTION_3_TITLE", "BURSE_SOLUTION_3_CONTENT")}
                            {generateSolutionItem("BURSE_SOLUTION_4_TITLE", "BURSE_SOLUTION_4_CONTENT")}
                            {generateSolutionItem("BURSE_SOLUTION_5_TITLE", "BURSE_SOLUTION_5_CONTENT")}
                        </div>
                }
            </div>
        </>
    )
}

export default Solution;