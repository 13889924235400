import "./Home.less";
import homeBurseLogo from '../../assets/images/logo/home-burse-logo.webp';
import homeBurseMobileLogo from '../../assets/images/logo/home-burse-logo-mobile.webp';
import homeBurseLogoCN from '../../assets/images/logo/home-burse-logo-cn.webp';
import homeBurseMobileLogoCN from '../../assets/images/logo/home-burse-logo-mobile-cn.webp';
import appLogo from '../../assets/images/logo/burse-text-logo.webp';
import { useLayout } from "../../hooks/LayoutContext";
import { useEffect, useState } from "react";
import LanguageSwitcher from "../../widget/LanguageSwitcher";
import { useIntl } from "react-intl";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons'
import { loginUrl } from "../../content/HomeContent";

interface Props {
    refHome: any;
    refAbout: any;
    refUSDTProbs: any;
    refSolution: any;
    refWorks: any;
    refPricing: any;
    refFooter: any;
    currentActive: any;
}

const Home = ({ refHome, refAbout, refUSDTProbs, refSolution, refWorks, refPricing, refFooter, currentActive }: Props) => {
    const { layoutState } = useLayout();
    const intl = useIntl();
    const [activeTab, setActiveTab] = useState('home');

    useEffect(() => {
        setActiveTab(currentActive)
    }, [currentActive]);

    const menuNav = (location: string) => {
        if (location !== 'memberLogin') {
            setActiveTab(location);
        }
        if (location === 'home') {
            if (refHome.current) {
                refHome.current.scrollIntoView();
            }
        }
        if (location === 'about') {
            if (refAbout.current) {
                refAbout.current.scrollIntoView();
            }
        }
        if (location === 'usdtProbs') {
            if (refUSDTProbs.current) {
                refUSDTProbs.current.scrollIntoView();
            }
        }
        if (location === 'solution') {
            if (refSolution.current) {
                refSolution.current.scrollIntoView();
            }
        }
        if (location === 'works') {
            if (refWorks.current) {
                refWorks.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (location === 'pricing') {
            if (refPricing.current) {
                refPricing.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (location === 'footer') {
            if (refFooter.current) {
                refFooter.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (location === 'memberLogin') {
            window.open(loginUrl)
        }
        setMenuOpened(false)
    }
    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const menuClick = () => {
        setMenuOpened(!menuOpened);
    }

    return (
        <>
            {/* Menu Backdrop */}
            {
                menuOpened === true ?
                    <div className="backdropMenu">
                        <div className="menuList">
                            <img alt="logoImageSmall" onClick={() => menuNav('home')} className="logoImageSmall" src={appLogo} />
                            <p className={activeTab === 'about' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('about')}>{intl.formatMessage({ id: "ABOUT" })}</p>
                            <p className={activeTab === 'usdtProbs' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('usdtProbs')}>{intl.formatMessage({ id: "USDT_TO_CASH" })}</p>
                            <p className={activeTab === 'solution' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('solution')}>{intl.formatMessage({ id: "SOLUTIONS" })}</p>
                            <p className={activeTab === 'works' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('works')}>{intl.formatMessage({ id: "HOW_IT_WORKS" })}</p>
                            <p className={activeTab === 'pricing' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('pricing')}>{intl.formatMessage({ id: "CHARGES" })}</p>
                            <p className={activeTab === 'footer' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('footer')}>{intl.formatMessage({ id: "CONTACT" })}</p>
                            <p className={activeTab === 'memberLogin' ? "textButton menu-active-white" : "textButton"} onClick={() => menuNav('memberLogin')}>{intl.formatMessage({ id: "MEMBER_LOGIN" })}</p>
                            <LanguageSwitcher refHome={refHome}></LanguageSwitcher>
                        </div>
                    </div>
                    :
                    null
            }
            {
                layoutState.dimension.width > 768 ?
                    null
                    :
                    <div className="mbButtonContainer">
                        <button name="menuButton" className="buttonStyle" onClick={() => menuClick()}><FontAwesomeIcon icon={menuOpened === false ? faBars : faXmark} fontSize={20} /></button>
                    </div>
            }
            <div className="home-wrap" ref={refHome}>
                <div className="home-logo-wrap">
                    <img className="home-logo-img" alt="burse-logo" src={layoutState.dimension.width > 768 ? (layoutState.locale === 'en' ? homeBurseLogo : homeBurseLogoCN) : (layoutState.locale === 'en' ? homeBurseMobileLogo : homeBurseMobileLogoCN)} />
                </div>
            </div>

            <ScrollToTop style={layoutState.dimension.width > 768 ? { bottom: 20, right: 20, zIndex: "99990" } : { bottom: 20, right: 20, width: 35, height: 35, zIndex: "99990" }} smooth top={500}
                component={<FontAwesomeIcon icon={faChevronUp} fontSize={20} />} color="#000000" />
        </>
    )
}

export default Home;