import "./USDTProbs.less";
import probsClip1 from '../../assets/images/clip/pin-clip-3.webp';
import prob1 from '../../assets/images/clip/prob-1.webp';
import prob2 from '../../assets/images/clip/prob-2.webp';
import prob3 from '../../assets/images/clip/prob-3.webp';
import prob4 from '../../assets/images/clip/prob-4.webp';
import prob5 from '../../assets/images/clip/prob-5.webp';
import prob1Hover from '../../assets/images/clip/prob-1-hover.webp';
import prob2Hover from '../../assets/images/clip/prob-2-hover.webp';
import prob3Hover from '../../assets/images/clip/prob-3-hover.webp';
import prob4Hover from '../../assets/images/clip/prob-4-hover.webp';
import prob5Hover from '../../assets/images/clip/prob-5-hover.webp';
import { useIntl } from "react-intl";
import { useState } from "react";
import { useLayout } from "../../hooks/LayoutContext";

interface Props {
    refUSDTProbs: any;
}

const USDTProbs = ({ refUSDTProbs }: Props) => {
    const intl = useIntl();
    const { layoutState } = useLayout();
    const [prob1Img, setProb1Img] = useState<any>(prob1);
    const [prob2Img, setProb2Img] = useState<any>(prob2);
    const [prob3Img, setProb3Img] = useState<any>(prob3);
    const [prob4Img, setProb4Img] = useState<any>(prob4);
    const [prob5Img, setProb5Img] = useState<any>(prob5);

    const hoverIn = (title: any) => {
        if (title === 'BURSE_PROB_1_TITLE') {
            setProb1Img(prob1Hover);
        } else
            if (title === 'BURSE_PROB_2_TITLE') {
                setProb2Img(prob2Hover);
            } else
                if (title === 'BURSE_PROB_3_TITLE') {
                    setProb3Img(prob3Hover);
                } else
                    if (title === 'BURSE_PROB_4_TITLE') {
                        setProb4Img(prob4Hover);
                    } else
                        if (title === 'BURSE_PROB_5_TITLE') {
                            setProb5Img(prob5Hover);
                        }
    };

    const hoverOut = (title: any) => {
        if (title === 'BURSE_PROB_1_TITLE') {
            setProb1Img(prob1);
        } else
            if (title === 'BURSE_PROB_2_TITLE') {
                setProb2Img(prob2);
            } else
                if (title === 'BURSE_PROB_3_TITLE') {
                    setProb3Img(prob3);
                } else
                    if (title === 'BURSE_PROB_4_TITLE') {
                        setProb4Img(prob4);
                    } else
                        if (title === 'BURSE_PROB_5_TITLE') {
                            setProb5Img(prob5);
                        }
    };

    const generateProbItem = (logo: any, title: any, content: any) => {
        return (
            <div className="prob-content-box" onMouseOver={() => hoverIn(title)} onMouseOut={() => hoverOut(title)}>
                <div className="prob-content-img-wrap">
                    <img className="prob-content-img" alt="burse-prob-logo" src={logo} />
                </div>
                <div className="prob-content-wrap">
                    <h2>{intl.formatMessage({ id: title })}</h2>
                    <span>{intl.formatMessage({ id: content })}</span>
                </div>
            </div>
        )
    };

    return (
        <>
            <div className="usdt-prob-wrap">
                <div className="usdt-vertical-title-wrap">
                    <span className="usdt-vertical-title">
                        {intl.formatMessage({ id: "PROBLEMS" })}
                    </span>
                </div>
                <div className="usdt-prob-content-box" ref={refUSDTProbs}>
                    <div className="usdt-side-logo">
                        <div className="usdt-side-logo-position">
                            <img className="usdt-clip-img" alt="usdt-clip" src={probsClip1} />
                        </div>
                        <div className="usdt-title-text">
                            {intl.formatMessage({ id: "USDT_TO_CASH" })}
                        </div>
                    </div>

                    <div className="usdt-content-box">
                        <div className="usdt-content-wrap">
                            {generateProbItem(prob1Img, "BURSE_PROB_1_TITLE", "BURSE_PROB_1_CONTENT")}
                            {generateProbItem(prob2Img, "BURSE_PROB_2_TITLE", "BURSE_PROB_2_CONTENT")}
                            {
                                layoutState.dimension.width >= 1024 ?
                                    null :
                                    (generateProbItem(prob5Img, "BURSE_PROB_5_TITLE", "BURSE_PROB_5_CONTENT"))
                            }
                        </div>
                        <div className="usdt-content-wrap box-02">
                            {generateProbItem(prob3Img, "BURSE_PROB_3_TITLE", "BURSE_PROB_3_CONTENT")}
                            {generateProbItem(prob4Img, "BURSE_PROB_4_TITLE", "BURSE_PROB_4_CONTENT")}
                        </div>
                        {
                            layoutState.dimension.width >= 1024 ?
                                <div className="usdt-content-wrap box-03">
                                    {generateProbItem(prob5Img, "BURSE_PROB_5_TITLE", "BURSE_PROB_5_CONTENT")}
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default USDTProbs;