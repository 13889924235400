import './App.less';
import { IntlProvider } from 'react-intl';
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import message_ms from './assets/i18n/ms.json';
import { Route, Routes } from 'react-router-dom';
import { SET_SCREENSIZE, useLayout } from './hooks/LayoutContext';
// import trackPathForAnalytics from './TrackPageForAnalytics';
import Content from './pages/Content';
import { useEffect } from 'react';

function App() {
  const { layoutState, layoutDispatch } = useLayout();

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN,
    'ms': message_ms
  };
  // const { pathname, search } = useLocation();

  // const analytics = useCallback(() => {
  //   trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
  // }, [pathname, search]);

  // useEffect(() => {
  //   analytics();
  // }, [analytics]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const dimension = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      layoutDispatch({ type: SET_SCREENSIZE, payload: dimension });
    })// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);


  return (
    <IntlProvider locale={layoutState.locale} messages={layoutState.locale ? messages[layoutState.locale] : ''} key={layoutState.locale}>
      <Routes>
        <Route path="/" element={<Content />}>
        </Route>
        <Route path="*" element={<Content />} />
      </Routes>
    </IntlProvider>
  );
}

export default App;
