import About from "./About/About";
import "./Content.less";
import Home from "./Home/Home";
import { useEffect, useState, useRef } from "react";
import Footer from "./Footer/Footer";
import USDTProbs from "./USDTProbs/USDTProbs";
import Solution from "./Solution/Solution";
import Works from "./Works/Works";
import Pricing from "./Pricing/Pricing";
import { useLayout } from "../hooks/LayoutContext";
import useIntersection from "../widget/useIntersection";

const Context = () => {
    const { layoutState } = useLayout();
    const [isSticky, setSticky] = useState<boolean>(false);
    const [isTransparent, setTransparent] = useState<boolean>(false);
    const [currentActive, setCurrentActive] = useState<any>('');
    const refHome = useRef(null);
    const refAbout = useRef(null);
    const refUSDTProbs = useRef(null);
    const refSolution = useRef(null);
    const refWorks = useRef(null);
    const refPricing = useRef(null);
    const refFooter = useRef(null);
    const viewHome = useIntersection(refHome, '15px');
    const viewAbout = useIntersection(refAbout, '-50px');
    const viewUSDT = useIntersection(refUSDTProbs, '15px');
    const viewSolution = useIntersection(refSolution, '15px');
    const viewWorks = useIntersection(refWorks, '400px');
    const viewPricing = useIntersection(refPricing, '15px');
    const viewFooter = useIntersection(refFooter, '0px');

    useEffect(() => {
        if (viewHome) {
            setCurrentActive('home');
        } else if (viewAbout) {
            setCurrentActive('about');
        } else if (viewUSDT) {
            setCurrentActive('usdtProbs');
        } else if (viewSolution) {
            setCurrentActive('solution');
        } else if (viewWorks) {
            setCurrentActive('works');
        } else if (viewPricing) {
            setCurrentActive('pricing');
        } else if (viewFooter) {
            setCurrentActive('footer');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewHome, viewAbout, viewUSDT, viewSolution, viewWorks, viewPricing, viewFooter]);

    useEffect(() => {
        if (layoutState.dimension.width > 768)
            window.addEventListener('scroll', () => {
                if (window.scrollY > window.innerHeight) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }

                if (window.scrollY > window.innerHeight + 50) {
                    setTransparent(true);
                } else {
                    setTransparent(false);
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="overall-wrap">
                <div className="content-wrap">
                    <Home currentActive={currentActive} refHome={refHome} refAbout={refAbout} refUSDTProbs={refUSDTProbs} refSolution={refSolution} refWorks={refWorks} refPricing={refPricing} refFooter={refFooter}></Home>
                    <About
                        currentActive={currentActive}
                        isTransparent={isTransparent} isSticky={isSticky} refAbout={refAbout} refHome={refHome} refUSDTProbs={refUSDTProbs} refSolution={refSolution} refWorks={refWorks} refPricing={refPricing} refFooter={refFooter}></About>
                    <USDTProbs refUSDTProbs={refUSDTProbs}></USDTProbs>
                    <Solution refSolution={refSolution}></Solution>
                    <Works refWorks={refWorks}></Works>
                    <Pricing refPricing={refPricing}></Pricing>
                    <Footer refFooter={refFooter}></Footer>
                </div>
            </div>
        </>
    )
}

export default Context;