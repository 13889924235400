import "./Footer.less";
import footerBurseLogo from '../../assets/images/logo/footer-burse-logo.webp';
import contactAddressLogo from '../../assets/images/logo/contact-address-logo.webp';
import contactPhoneLogo from '../../assets/images/logo/contact-phone-logo.webp';
import contactEmailLogo from '../../assets/images/logo/contact-email-logo.webp';
import footerClip1 from '../../assets/images/clip/pin-clip-1.webp';
import { useIntl } from "react-intl";
import { contactNumber, enquiryEmail, officeAddress } from "../../content/HomeContent";

interface Props {
    refFooter: any;
}

const Footer = ({ refFooter }: Props) => {
    const intl = useIntl();
    const currentYear = new Date().getFullYear();

    const generateContactItem = (logo: any, title: any, content: any) => {
        return (
            <div className="footer-contact-wrap">
                <div className="footer-contact-info-logo">
                    <img className="footer-contact-logo-img" alt="burse-contact-logo" src={logo} />
                </div>
                <div className="footer-contact-info">
                    <div className="footer-contact-info-title">
                        {intl.formatMessage({ id: title })}
                    </div>
                    <div className="footer-contact-info-content">
                        {content}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <>
            <div className="footer-wrap" ref={refFooter}>
                <div className="footer-left">
                    <div className="footer-title-box">
                        <div className="footer-title-box-wrap">
                            <img className="footer-clip-img" alt="footer-clip" src={footerClip1} />
                        </div>
                        <div className="footer-title-text">
                            {intl.formatMessage({ id: "CONTACT_US" })}
                        </div>
                    </div>
                    <div className="footer-logo-box">
                        <div style={{ height: '80%', width: '100%', objectFit: 'cover' }}>
                            <img className="footer-logo-img" alt="burse-logo-footer" src={footerBurseLogo} />
                        </div>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-contact-box">
                        <div className="footer-contact-box-wrap">
                            {generateContactItem(contactAddressLogo, "OFFICE_ADD", officeAddress)}
                            {generateContactItem(contactPhoneLogo, "CONTACT_NUMBER", contactNumber)}
                            {generateContactItem(contactEmailLogo, "EMAIL_ADD", enquiryEmail)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright-box">
                <div>
                    {(intl.formatMessage({ id: "COPYRIGHT" }).replace("{currentYear}", String(currentYear)))}
                    {(intl.formatMessage({ id: "RIGHTS" }))}
                </div>
                <div className="footer-tnc-box">
                    <div>
                        {(intl.formatMessage({ id: "PRIVACY_POLICY" }))}
                    </div>
                    <hr className="footer-hr" />
                    <div>
                        {(intl.formatMessage({ id: "TNC" }))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;