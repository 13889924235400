import "./Works.less";
import worksContent from '../../assets/images/background/works-content-img.webp';
import worksMobileContent1 from '../../assets/images/background/works-mobile-content-1.webp';
import worksMobileContent2 from '../../assets/images/background/works-mobile-content-2.webp';
import worksContentCN from '../../assets/images/background/works-content-img-cn.webp';
import worksMobileContent1CN from '../../assets/images/background/works-mobile-content-1-cn.webp';
import worksMobileContent2CN from '../../assets/images/background/works-mobile-content-2-cn.webp';
import worksClip1 from '../../assets/images/clip/pin-clip-5.webp';
import burseTextLogo from '../../assets/images/logo/burse-text-logo.webp';
import { useLayout } from "../../hooks/LayoutContext";

interface Props {
    refWorks: any;
}


const Works = ({ refWorks }: Props) => {
    const { layoutState } = useLayout();

    return (
        <>
            <div className="works-wrap">
                <div className="works-left-content-wrap"></div>
                <div className="works-right-content-wrap"></div>

                <div className="works-overlay-wrap">
                    <div className="works-overlay-upper-content" ref={refWorks}>
                        <div className="works-clip-logo">
                            <img className="works-clip-img" alt="works-clip" src={worksClip1} />
                        </div>
                        <div className="works-title-logo">
                            <img className="works-logo-img" alt="works-logo" src={burseTextLogo} />
                        </div>
                    </div>
                    <div className="works-overlay-lower-content">
                        {
                            layoutState.dimension.width > 768 ?
                                <img className="works-content-img" alt="burse-works-content" src={(layoutState.locale === 'en' ? worksContent : worksContentCN)} />
                                :
                                <>
                                    <img className="works-content-img-mb-1" alt="burse-works-content-mb-1" src={(layoutState.locale === 'en' ? worksMobileContent1 : worksMobileContent1CN)} />
                                    <img className="works-content-img-mb-2" alt="burse-works-content-mb-2" src={(layoutState.locale === 'en' ? worksMobileContent2 : worksMobileContent2CN)} />
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Works;